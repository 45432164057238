import { Link as NavLink } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import {
  CtaButton,
  purpleLinkStyle,
  TitleSection,
  TitleText,
  TextOuter,
  largeCTAButtonStyle,
  Outer as _Outer,
} from '../../../components/join-forms/styled'
import PageLayout from '../../../components/layout/Page'
import { Spacer } from '../../../components/modules'
import { Color } from '../../../components/theme'
import { trackEvent } from '../../../components/utils'
import { CAREERS_PATH } from '../../../constants'

const Outer = styled(_Outer)`
  margin-top: 40px;

  img {
    margin-bottom: 40px;
  }
`

const ContactUs = styled.span`
  ${purpleLinkStyle}
`

const Link = styled.a`
  ${largeCTAButtonStyle}
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${Color.PURPLE_6};
    color: white;
  }
`

const Confirm = () => (
  <PageLayout
    head={{
      title: 'Thank You',
      description: 'We received your details',
    }}
    header={{
      children: (
        <NavLink to={CAREERS_PATH}>
          <CtaButton
            title={'Become an Assistant'}
            isValid
            id={'form-assistant'}
          />
        </NavLink>
      ),
    }}
    hideFooter
  >
    <TitleSection>
      <TitleText>{'Thank you!'}</TitleText>
    </TitleSection>
    <Outer>
      <img src={'/images/joinSuccess.png'} width={375} />
      <TextOuter>
        <p>
          {
            'We’ve received your details, and will start looking for an assistant who matches your requirements.'
          }
        </p>
        <p>
          {
            'Due to the current volume of assistant requests, matching typically takes '
          }
          <b>{'5-10 business days'}</b>
          {
            '. You’ll receive an email from us as soon as your assistant is ready to get started.'
          }
        </p>
        <p>
          {
            'Get a jump start by reviewing all the things your Double assistant can help you with.'
          }
        </p>
      </TextOuter>
      <Link href={'https://withdouble.com/tasks-to-delegate'}>
        {'Explore Double'}
      </Link>
      <ContactUs
        id={'confirm_contact'}
        noResponsive
        onClick={() => {
          try {
            window.Intercom('show')
            trackEvent('form_confirm_contact')
          } catch (error) {}
        }}
      >
        {'Or contact us with any questions'}
      </ContactUs>
      <Spacer />
    </Outer>
  </PageLayout>
)

export default Confirm
