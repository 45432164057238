import styled, { css } from 'styled-components'

import {
  Button,
  Input as _Input,
  EmailInput as _EmailInput,
  SelectInput as _SelectInput,
  SelectInputWithSeparator as _SelectInputWithSeparator,
  MultilineTextInput as _MultilineTextInput,
} from '../forms'
import { TextareaEl } from '../forms/styled'
import { mobile, screenSize3 } from '../mixins'
import { Color } from '../theme'
import {
  Heading1,
  BodySemibold,
  regularStyle,
  bodyMediumStyle,
  bodySemiboldStyle,
  subheadRegularStyle,
} from '../typography'

export const Row = styled.div`
  display: flex;
  margin-bottom: 24px;
  & > * {
    flex: 1 1 50%;
  }
`

export const ContinueButton = styled(Button)`
  margin: 8px 0;
  margin-top: 16px;
  width: 100%;

  &:focus {
    background-color: ${Color.PURPLE_6};
  }

  ${mobile(css`
    margin-top: 40px;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  `)}
`

export const AssistantRedirect = styled.div`
  flex: 1 0 0;
  margin-top: 48px;
  text-align: center;

  a {
    color: ${Color.PURPLE_6};
    text-decoration: none;
  }
`

const inputStyle = css`
  margin: 0 0 8px 0;
  transition: box-shadow 200ms ease;
  min-width: 0px;

  &:focus {
    box-shadow: inset 0 0 0 2px ${Color.PURPLE_4};
  }

  &.error:not(:focus) {
    box-shadow: inset 0 0 0 2px #e6635c;
  }

  &:disabled {
    background: white;
    border: 1px solid ${Color.GRAY_3};
  }

  ${mobile(css`
    margin-left: 0;
    margin-right: 0;
  `)}
`

export const Input = styled(_Input)`
  ${inputStyle}
`

export const HowHeardOtherInput = styled(Input)`
  margin-left: 4px;
  width: 80%;
`

export const SelectInput = styled(_SelectInput)`
  ${inputStyle}
`

export const SelectInputWithSeparator = styled(_SelectInputWithSeparator)`
  ${inputStyle}
`

export const EmailInput = styled(_EmailInput)`
  ${inputStyle}
`

export const MultilineTextInput = styled(_MultilineTextInput)`
  height: 128px;
  ${inputStyle}
`

export const Spacer = styled.div`
  height: 40px;
`

export const SmallSpacer = styled.div`
  height: 12px;
`

export const Inner = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
  margin: 42px auto 0 auto;
  width: 528px;
  ${mobile(css`
    max-width: 327px;
    margin: 0 0 0 0;
  `)}
`

export const TitleText = styled(Heading1)`
  margin-bottom: 24px;
`

export const TitleSection = styled.div`
  flex: 1 0 0;
  margin-top: 80px;
  text-align: center;

  ${mobile(css`
    margin-top: 48px;
  `)}
`

export const SectionHeader = styled(BodySemibold)<{
  required?: boolean
  noPaddingTop?: boolean
}>`
  padding: 22px 0 0 !important;
  margin: 16px 0 0 !important;

  ${({ noPaddingTop }) =>
    noPaddingTop &&
    `
  padding-top: 0px !important;
  margin-top: 0px !important;
  `}

  ${mobile(css`
    font-size: 15px;
    line-height: 18px;
    margin-left: 0;
    margin-right: 0;
  `)}

  ${({ required }) =>
    !!required &&
    css`
      &:after {
        content: ' *';
        color: ${Color.FLAMINGO_6};
      }
    `}
`

export const Slider = styled.span`
  border-radius: 16px;
  display: block;
  height: 56px;
  width: 100%;
  background-color: ${Color.GRAY_2};
  transition: 0.4s;
  margin: 0 auto;
  position: absolute;

  &::before {
    border-radius: 12px;
    position: absolute;
    content: '';
    height: 48px;
    width: 50%;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
  }
`

export const LabelWrapper = styled.div`
  display: block;
  z-index: 10;
  margin: 0 auto;
  height: 56px;
  width: 100%;
  position: absolute;
`

export const SliderLabel = styled.span<{ isActive?: boolean }>`
  ${regularStyle}
  position: absolute;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: ${Color.GRAY_6};
  line-height: 29px;
  z-index: 10;
  width: 50%;
  text-align: center;

  display: flex;
  align-items: stretch;
  justify-content: stretch;
  height: 100%;

  ${({ isActive }) =>
    !!isActive &&
    css`
      color: black;
    `}
`

export const SliderWrapper = styled.label`
  cursor: pointer;
  display: block;
  height: 56px;
  width: 100%;
  color: black;
  margin: 8px auto 0;

  input {
    display: none;

    &:focus + ${Slider} {
      box-shadow: 0 0 0.0625rem white;
    }
  }
`

export const NameInputs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${screenSize3(css`
    flex-direction: column;
  `)}

  input {
    flex-grow: 1;
  }

  input + input {
    margin-left: 8px;

    ${screenSize3(css`
      margin-left: 0px;
    `)}
  }
`

export const TextArea = styled(TextareaEl)`
  resize: none;
  height: 168px;
`

export const FormOuter = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

export const CtaButton = styled(Button)`
  z-index: 30;
  font-size: 16px;
  line-height: 160%;
  padding: 9px 16px;
  height: unset;
  box-shadow: unset;
  &:hover {
    background-color: ${Color.PURPLE_6};
  }

  ${screenSize3(css`
    margin-right: 30px;
    visibility: hidden;
  `)}
`

export const purpleLinkStyle = css`
  ${bodyMediumStyle}
  text-align: center;
  padding: 13px 0px;
  margin: 8px;
  cursor: pointer;

  color: ${Color.PURPLE_5};
  &:hover {
    color: ${Color.PURPLE_5};
  }
`

export const Outer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 16px 0px;
`

export const ContentOuter = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  align-items: flex-end;
  justify-content: center;
  margin-top: 78px;

  & > * {
    width: 372px;

    @media (min-width: 850px) {
      &:nth-child(odd) {
        margin-right: 80px;
      }
    }

    @media (max-width: 850px) {
      min-width: 100%;
      width: 100%;
      padding: 20px;
    }
  }
`

export const TasksItemOuter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

export const TextOuter = styled.div`
  ${subheadRegularStyle}
  max-width: 816px;

  p {
    margin-bottom: 36px;
  }
`

export const largeCTAButtonStyle = css`
  ${bodySemiboldStyle}
  min-width: 372px;
  min-height: 52px;
  background-color: ${Color.PURPLE_5};

  border-radius: 16px;

  &:focus {
    background-color: ${Color.PURPLE_6};
  }

  color: white;

  ${mobile(css`
    color: white;
    min-width: 100%;
    width: 100%;
  `)}
`

export const LargeCTAButton = styled.button`
  ${largeCTAButtonStyle}
`
